import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import Footer from './footer.js';
import "../assets/css/reset.css";
import "../assets/css/common.css";
import iconPc from '../assets/img/nissan_logo_pc.png';
import iconSp from '../assets/img/nissan_logo_sp.png';
import statusBarNissanPassportPc from '../assets/img/step_02_pc.png';
import statusBarNissanPassportSp from '../assets/img/step_02_sp.png';
import loadingPc from '../assets/img/loading_pc.png';
import loadingSp from '../assets/img/loading_sp.png';


function Loading () {
    let text;
	if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)) {
		text =
			<div class="main">
				<div class="main-inner">
					<div class="logo-nissan">
						<h1><img src={iconSp} alt="NISSAN" /></h1>
					</div>
					<div class="title">
						NISSAN PASSPORT ID認証で限定チャンネルにご招待
					</div>
					<div class="main-container">
						<div class="status-bar">
							<img src={statusBarNissanPassportSp} alt="認証状況" />
						</div>
						<div class="loading">
							<img src={loadingSp} alt="ロード中"/>
						</div>
					</div>
					<div class="margin-under"></div>
				</div>
			</div>;
	}
	//PC画面
	else {

		text =
			<div class="main">
				<div class="main-inner">
					<div class="logo-nissan">
						<h1><img src={iconPc} alt="NISSAN" /></h1>
					</div>
					<div class="title">
						NISSAN PASSPORT ID認証で限定チャンネルにご招待
					</div>
					<div class="main-container">
                        <div class="status-bar">
                            <img src={statusBarNissanPassportPc} alt="認証状況" />
                        </div>
						<div class="loading">
							<img src={loadingPc} alt="ロード中"/>
						</div>
					</div>
					<div class="margin-under"></div>
				</div>
			</div>;
	}
	return (
		<>
			<body>
				<div class="footer-ajust">
					<main>
						{text}
					</main>
					<Footer />
				</div>
			</body>
		</>
	);
}

export default Loading;