function Footer() {
    return (
			<footer>
				<div class="footer_contents">
						<p class="nbf-light">&copy;Nissan 2024</p>
				</div>
			</footer>
    );
}

export default Footer;
