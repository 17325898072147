import './App.css';
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Buffer } from "buffer";
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import "./assets/css/reset.css";
import "./assets/css/common.css";
import Home from "./pages/home.js";
import Loading from "./pages/loading.js";
import Success from "./pages/success.js";
import Error from './pages/error.js';
import Registered from './pages/registered.js';
import Error3 from './pages/error3.js';

window.Buffer = window.Buffer || Buffer;

function App() {
    return (
        <BrowserRouter>
            <Routes>
                {/* <Route path={`/`} element={<Home />} />
                <Route path={`/loading`} element={<Loading />} /> */}
                <Route path={`/success`} element={<Success />} />
                <Route path={`/error`} element={<Error />} />
                <Route path={`/registered`} element={<Registered />} />
                {/* <Route path={`/error2`} element={<Error2 />} />
                <Route path={`/error3`} element={<Error3 />} /> */}
            </Routes>
        </BrowserRouter>
    );
};

export default App;