import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route, Link, useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from 'react-router-dom';
import Footer from './footer.js';
import "../assets/css/reset.css";
import "../assets/css/common.css";
import iconPc from '../assets/img/nissan_logo_pc.png';
import iconSp from '../assets/img/nissan_logo_sp.png';
import statusBarNissanPassportPc from '../assets/img/step_error_01_pc.png';
import statusBarNissanPassportSp from '../assets/img/step_error_01_sp.png';

function Error () {
    let text;
	if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)) {
		text =
			<div class="main">
				<div class="main-inner">
					<div class="logo-nissan">
						<h1><img src={iconSp} alt="NISSAN" /></h1>
					</div>
					<div class="title">
						NISSAN ID 認証エラー
					</div>
					<div class="info-error">
						このNISSAN IDは既にDiscordと連携されているか、<br />
						システムエラーにより認証に失敗した可能性があります。<br />
						<br />
						お手数をおかけしますが、しばらく時間を置いてから再度お試しください<br />
						問題が解決しない場合は、Discordサーバー内の「🎫｜お問い合わせ」チャンネルからご連絡ください。<br />
					</div>
					<div class="margin-under"></div>
				</div>
			</div>;
	}
	//PC画面
	else {

		text =
			<div class="main">
				<div class="main-inner">
					<div class="logo-nissan">
						<h1><img src={iconPc} alt="NISSAN" /></h1>
					</div>
					<div class="title">
						NISSAN ID 認証エラー
					</div>
					<div class="main-container">
                        <div class="info-error">
							このNISSAN IDは既にDiscordと連携されているか、<br />
							システムエラーにより認証に失敗した可能性があります。<br />
							<br />
							お手数をおかけしますが、しばらく時間を置いてから再度お試しください<br />
							問題が解決しない場合は、Discordサーバー内の「🎫｜お問い合わせ」チャンネルからご連絡ください。<br />
						</div>
					</div>
					<div class="margin-under"></div>
				</div>
			</div>;
	}
	return (
		<>
			<body>
				<div class="footer-ajust">
					<main>
						{text}
					</main>
					<Footer />
				</div>
			</body>
		</>
	);
}

export default Error;