import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Buffer } from "buffer";
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import Footer from './footer.js';
import "../assets/css/reset.css";
import "../assets/css/common.css";
import iconPc from '../assets/img/nissan_logo_pc.png';
import iconSp from '../assets/img/nissan_logo_sp.png';
import statusBarNissanPassportPc from '../assets/img/step_03_pc.png';
import statusBarNissanPassportSp from '../assets/img/step_03_sp.png';
import successImagePc from '../assets/img/Icon.png';
import successImageSp from '../assets/img/Icon.png';

function Success() {
    let text;
    //スマホ・タブレット画面
    if (navigator.userAgent.match(/(iPhone|iPod|Android.*Mobile)/i)) {
        text =
            <div class="main">
                <div class="main-inner">
                    <div class="logo-nissan">
                        <h1><img src={iconSp} alt="HAYACAWA" /></h1>
                    </div>
                    <div class="title">
                        NISSAN ID 認証完了
                    </div>
                    <div class="title-success">
                        NISSAN ID認証に成功しました！<br />
                        NISSAN PASSPORTをお持ちの方は<br />
                        限定チャンネルに参加することができます。
                    </div>
                    <div class="logo-gif">
                        <img src={successImagePc} alt="HAYACAWAロゴのgif" />
                    </div>
                    <div class="info-description">
                        <p class="info-p">
                            この画面を閉じて、<br />
                            Discordへ戻ってください。<br />
                            NISSAN IDの認証後、Discordのロール付与完了まで<br />
                            最大2分程度かかる場合があります。
                        </p>
                    </div>
                    <div class="margin-under"></div>
                </div>
            </div>

    }
    //PC画面
    else {
        text =
            <div class="main">
                <div class="main-inner">
                    <div class="logo-nissan">
                        <h1><img src={iconPc} alt="HAYACAWA" /></h1>
                    </div>
                    <div class="title">
                        NISSAN ID 認証完了
                    </div>
                    <div class="main-container">
                        <div class="title-success">
                            NISSAN ID認証に成功しました！<br />
                            NISSAN PASSPORTをお持ちの方は<br />
                            限定チャンネルに参加することができます。<br />
                        </div>
                        <div class="logo-gif">
                            <img src={successImagePc} alt="HAYACAWAロゴのgif" />
                        </div>
                        {/* <div class="info-wrapper"> */}
                        {/* <div class="info-wrapper">
                                                        <div class="info-wrapper"> */}
                        <div class="info-description">
                            <p class="info-p">
                                この画面を閉じて、Discordへ戻ってください。<br />
                                NISSAN IDの認証後、Discordのロール付与完了まで<br />
                                最大2分程度かかる場合があります。
                            </p>
                        </div>
                    </div>
                    <div class="margin-under"></div>
                </div>
            </div>;
    }
    //共通return
    return (
        <>
            <body>
                <div class="footer-ajust">
                    <main>
                        {text}
                    </main>
                    <Footer />
                </div>
            </body>
        </>
    );
}

export default Success;